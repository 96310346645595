import React from 'react'
import ActionBar from '../../molecules/ActionBar'
import Earn1 from './Earn1'
import Earn2 from './Earn2'
import Earn3 from './Earn3'
import Earn4 from './Earn4'

interface LinkItem {
  title: string
  description: string
  url: string
}

export interface PageEarnContent {
  title: string
  description: string
  earnActions: LinkItem[]
}

export default function PageEarn({ content }: { content: PageEarnContent }) {
  return (
    <>
      <Earn1 />
      <Earn2 />
      <Earn3 />
      <Earn4 />
      <ActionBar actions={content.earnActions} />
    </>
  )
}
