import React from 'react'
import Section from '../../molecules/Section'
import styles from './Earn1.module.css'
import { graphql, useStaticQuery } from 'gatsby'

interface LinkItem {
  title: string
  description: string
  url: string
}

export interface Earn1Content {
  title: string
  description: string
  links: {
    title: string
    links: LinkItem[]
  }
}

const query = graphql`
  query EarnEarn1Query {
    earnJson {
      earn1 {
        title
        description
        links {
          title
          links {
            title
            description
            url
          }
        }
      }
    }
  }
`

export default function Earn1() {
  const data = useStaticQuery(query)
  const content: Earn1Content = data.earnJson.earn1

  return (
    <Section title={content.title} className={styles.section}>
      <div>
        <h3 className={styles.getStartedTitle}>{content.links.title}</h3>

        {content.links.links.map((item: LinkItem) => (
          <a className={styles.getStartedItem} href={item.url} key={item.title}>
            <h4>{item.title}</h4>
            <p>{item.description}</p>
          </a>
        ))}
      </div>
    </Section>
  )
}
