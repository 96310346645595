import React from 'react'
import { graphql, PageProps } from 'gatsby'
import Layout from '../components/Layout'
import PageEarn, { PageEarnContent } from '../components/pages/earn'

export default function PageGatsbyEarn(props: PageProps) {
  const { earnJson } = props.data as any
  const { title, description } = earnJson as PageEarnContent

  return (
    <Layout
      title={title}
      description={description}
      uri={props.uri}
      location={props.location}
    >
      <PageEarn content={earnJson} />
    </Layout>
  )
}

export const contentQuery = graphql`
  query EarnPageQuery {
    earnJson {
      title
      description
      earnActions {
        title
        description
        url
      }
    }
  }
`
