import React from 'react'
import Section from '../../molecules/Section'
import styles from './Earn3.module.css'
import { graphql, useStaticQuery } from 'gatsby'

interface LinkItem {
  title: string
  description: string
  url: string
}

export interface Earn3Content {
  title: string
  description: string
  links: {
    title: string
    links: LinkItem[]
  }
}

const query = graphql`
  query EarnEarn3Query {
    earnJson {
      earn3 {
        title
        description
        links {
          title
          links {
            title
            description
            url
          }
        }
      }
    }
  }
`

export default function Earn3() {
  const data = useStaticQuery(query)
  const content: Earn3Content = data.earnJson.earn3

  return (
    <Section
      title={content.title}
      description={content.description}
      className={styles.section}
    >
      <div className={styles.grid}>
        <div>
          <h3 className={styles.getStartedTitle}>{content.links.title}</h3>

          {content.links.links.map((item: LinkItem) => (
            <a
              className={styles.getStartedItem}
              href={item.url}
              key={item.title}
            >
              <h4>{item.title}</h4>
              <p>{item.description}</p>
            </a>
          ))}
        </div>
      </div>
    </Section>
  )
}
